import React from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  SimpleGrid,
  Icon,
  Grid,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import {
  FaBitcoin,
  FaSearch,
  FaNewspaper,
  FaBasketballBall,
  FaDice,
  FaBox,
  FaAngleRight,
} from "react-icons/fa";
import SportsImage from "assets/img/blog/sports-image.webp";
import CasinoImage from "assets/img/blog/casino-image.webp";
import stakeDailyRacesImage from "assets/img/promotions/stake-daily-races.webp";
import stakeWeeklyRaffleImage from "assets/img/promotions/stake-weekly-raffle.webp";
import conquerTheCasinoImage from "assets/img/promotions/conquer-the-casino.webp";
import stakeVsEddieImage from "assets/img/promotions/stake-vs-eddy.webp";
import theLevelUpImage from "assets/img/promotions/the-level-up.webp";
import multiplieRaceImage from "assets/img/promotions/multiplier-race.webp";
import chaosCollectorImage from "assets/img/promotions/chaos-collector.webp";
import pragmaticBlackjackImage from "assets/img/promotions/pragmatic-blackjack.webp";
import pragmaticDropImage from "assets/img/promotions/pragmatic-drop.webp";
import textBannerImage from "assets/img/promotions/group-banner-default.png";

const PromotionSports = () => {
  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const endTimeColor = useColorModeValue("gray.800", "gray.400");

  const textColor1 = useColorModeValue("gray.800", "gray.500");
  const iconColor = useColorModeValue("gray.600", "gray.300");
  const tabBg = useColorModeValue("gray.100", "gray.700");
  // const inputBg = useColorModeValue("gray.200", "gray.700");

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "65px" }}
      px={{ base: 4, md: 8 }}
      bg={bg}
      color={textColor}
      minH="100vh"
    >
      <Box padding="4" bg={bg}>
        {/* Header */}
        <Flex
          justify="space-between"
          align="center"
          mb="6"
          height={115}
          backgroundColor={tabBg}
          p={4}
        >
          {/* Text on the left */}
          <Text fontSize="2xl" fontWeight="bold" color={textColor}>
            Sports
          </Text>

          {/* Text Banner Image */}
          <Image
            src={textBannerImage}
            alt="Banner"
            width="200px" // Adjust size as needed
            height="auto"
          />
        </Flex>

        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing="6">
          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={stakeDailyRacesImage}
              alt="UFC Picks & Expert UFC Predictions"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Stake&apos;s Daily Races
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={stakeWeeklyRaffleImage}
              alt="2024 MLB Predictions & World Series Picks"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Stake&apos;s Weekly Raffle
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={conquerTheCasinoImage}
              alt="New Games on Stake: August 30th 2024 Casino Releases & Sports Promos"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Conquer the Casino!
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={stakeVsEddieImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Stake vs Eddie
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={theLevelUpImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              The Level Up
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={multiplieRaceImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Multiplier Race
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={chaosCollectorImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Chaos Collector
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={pragmaticBlackjackImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Pragmatic Blackjack Win Streak Showdown: $25k Prize Pool
            </Text>
          </Box>

          <Box backgroundColor={bg} borderRadius="md" p="4">
            <Image
              src={pragmaticDropImage}
              alt="Another Blog Post Title"
              borderRadius="md"
              mb="4"
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "translateY(-10px)" }}
            />
            <Text color={endTimeColor} fontSize="sm" mb="2">
              Ends at 6:29 PM 12/31/2024
            </Text>
            <Text color={textColor} fontWeight="bold" fontSize="lg" mb="2">
              Pragmatic Drops & Wins Slots
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default PromotionSports;
