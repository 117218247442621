import React from "react";
import {
    Box,
    Image,
    Text,
    Flex,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

import logoImage from "assets/img/blog/logo-image.png";
import StakeNewsImage1 from "assets/img/blog/Stake News/image-1.webp";
import StakeNewsImage2 from "assets/img/blog/Stake News/image-2.webp";
import StakeNewsImage3 from "assets/img/blog/Stake News/image-3.webp";
import StakeNewsImage4 from "assets/img/blog/Stake News/image-4.webp";
import StakeNewsImage5 from "assets/img/blog/Stake News/image-5.webp";
import StakeNewsImage6 from "assets/img/blog/Stake News/image-6.webp";
import StakeNewsImage7 from "assets/img/blog/Stake News/image-7.webp";
import { NavLink } from "react-router-dom";

const StakeNews = () => {

    const tabBg = useColorModeValue("gray.100", "gray.700");
    const bg = useColorModeValue("white", "gray.800");
    const headerbg = useColorModeValue("gray.200", "gray.700");
    const textColor = useColorModeValue("gray.800", "white");

    const header = useColorModeValue("gray.800", "white");
    // const inputBg = useColorModeValue("gray.200", "gray.700");

    return (
        <Flex
            flexDirection="column"
            pt={{ base: "120px", md: "65px" }}
            px={{ base: 4, md: 8 }}
            bg={bg}
            color={textColor}
            minH="100vh"
        >
            <Box padding="4" bg={bg}>
                {/* Crypto Header */}
                <Flex justify="space-between" alignItems="center" bg={tabBg}>
                    <Text fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} fontWeight={"medium"} color={textColor} pl={4}>
                        Stake News
                    </Text>
                    <Image
                        src={logoImage}
                        alt="Featured Blog"
                        borderRadius="md"
                        maxWidth={{ base: "100%", sm: "50%", md: "50%" }}
                        transition="all 0.2s ease-in-out"
                        _hover={{ transform: "translateY(-10px)" }}
                    />



                </Flex>

                {/* Blog Posts Section */}
                <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} spacing="6">
                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={StakeNewsImage1}
                            alt="UFC Picks & Expert UFC Predictions"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={StakeNewsImage2}
                            alt="2024 MLB Predictions & World Series Picks"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>

                    <Box backgroundColor={bg} borderRadius="md" p="4">
                        <Image
                            src={StakeNewsImage3}
                            alt="New Games on Stake: August 30th 2024 StakeNews Releases & Sports Promos"
                            borderRadius="md"
                            mb="4"
                            transition="all 0.2s ease-in-out"
                            _hover={{ transform: "translateY(-10px)" }}
                        />
                        <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                            How to play Dise at Stack
                        </Text>
                        <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                            Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                            Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                            odds on all UFC fights and place your bets.
                        </Text>
                    </Box>
                    <NavLink to="/blog/ufc-picks">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={StakeNewsImage4}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                UFC Picks & Expert UFC Predictions
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                Get expert UFC 306 picks and predictions on Sean O&apos;Malley vs
                                Merab Dvalishvili and the full UFC fight card! Discover the best MMA
                                odds on all UFC fights and place your bets.
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/new_games_on_stake">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={StakeNewsImage5}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                New Games on Stake: August 30th 2024 Casino Releases & Sports Promos
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                We&apos;re wrapping up the week by looking at top sporting events,
                                22 new-release games &amp; fresh bet bonuses and promos! Find out
                                how to join the latest casino competitions today!
                            </Text>
                        </Box>
                    </NavLink>
                    <NavLink to="/blog/new_games_on_casino">
                        <Box backgroundColor={bg} borderRadius="md" p="4">
                            <Image
                                src={StakeNewsImage6}
                                alt="Another Blog Post Title"
                                borderRadius="md"
                                mb="4"
                                transition="all 0.2s ease-in-out"
                                _hover={{ transform: "translateY(-10px)" }}
                            />
                            <Text color={textColor} fontWeight="medium" fontSize={{ base: 'xl', md: '2xl', lg: '3xl' }} mb="2">
                                New Games on Stake: August 23rd 2024 Casino Releases & Sports Promos
                            </Text>
                            <Text color={"gray.500"} fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }}>
                                With a drop of 11 new games and plenty of casino & sports betting
                                promos, there&apos;s plenty of reasons to be excited this week! Find
                                out how to get involved in our weekly wrap up!
                            </Text>
                        </Box>
                    </NavLink>
                </SimpleGrid>
            </Box>
        </Flex>
    );
};

export default StakeNews;
