import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Spacer,
  Icon,
  Grid,
  VStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
  useColorMode,
  keyframes,
  Stack,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaGoogle,
  FaLine,
  FaTwitch,
  FaBasketballBall,
  FaBitcoin,
  FaDollarSign,
  FaTable,
  FaGamepad,
  FaHorseHead,
  FaStream,
  FaDice,
  FaBolt,
  FaGift,
  FaStar,
  FaRocket,
  FaCrown,
  FaTree,
  FaRedo,
} from "react-icons/fa";
import { GiCardJoker, GiConsoleController } from "react-icons/gi";
import { MdSportsSoccer } from "react-icons/md";
import { CgCardSpades } from "react-icons/cg";
import casinoImage from "assets/img/casino-image.jpg";
import sportsImage from "assets/img/sports-image.jpg";
import mastercardLogo from "assets/img/mastercard-logo.png";
import applePayLogo from "assets/img/apple-pay-logo.png";
import googlePayLogo from "assets/img/google-pay-logo.png";
import samsungPayLogo from "assets/img/samsung-pay-logo.jpg";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

import promotionalOne from "assets/img/home/promotional-1.avif";
import promotionalSecond from "assets/img/home/promotional-2.avif";

import {
  FacebookIcon,
  GoogleIcon,
  TwitchIcon,
  LineIcon,
} from "components/Icons/Icons";

import blueGradient from "assets/svg/home/blue-gradient.svg";
import greenGradient from "assets/svg/home/green-gradient.svg";

import blueGradientMobile from "assets/svg/home/blue-gradient-mobile.svg";
import greenGradientMobile from "assets/svg/home/green-gradient-mobile.svg";

import headerBg from "assets/svg/home/header-background.svg";

import casinoBanner from "assets/img/home/casino-banner.avif";
import sportsBanner from "assets/img/home/sports-banner.avif";

import promotionsVideo from "assets/video/home/promotions.webm";
import bonusVideo from "assets/video/home/bonus.webm";
import returnToPlayerVideo from "assets/video/home/return-to-player.webm";
import rafflesVideo from "assets/video/home/raffles.webm";

import communityImage from "assets/img/home/community.avif";

import currencyUsdtImage from "assets/svg/home/vertical-carousal/currency-usdt.svg";
import currencyBtcImage from "assets/svg/home/vertical-carousal/currency-btc.svg";
import currencyEthImage from "assets/svg/home/vertical-carousal/currency-eth.svg";
import currencyInrImage from "assets/svg/home/vertical-carousal/currency-inr.svg";

import billieJeanKingCupImage from "assets/svg/home/horizontal-carousal/billie-jean-king-cup.svg";
import davisCupImage from "assets/svg/home/horizontal-carousal/davis-cup.svg";
import drakeImage from "assets/svg/home/horizontal-carousal/drake.svg";
import enyimbaFcImage from "assets/svg/home/horizontal-carousal/enyimba-fc.svg";
import evertonImage from "assets/svg/home/horizontal-carousal/everton.svg";
import juventudeImage from "assets/svg/home/horizontal-carousal/juventude.svg";
import stakeF1Image from "assets/svg/home/horizontal-carousal/stake-f1.svg";
import teamSecretImage from "assets/svg/home/horizontal-carousal/team-secret.svg";
import ufcImage from "assets/svg/home/horizontal-carousal/ufc.svg";

import blueBg from "assets/svg/home/sponsorship/bg-blue.svg";
import greenBg from "assets/svg/home/sponsorship/bg-green.svg";
import redBg from "assets/svg/home/sponsorship/bg-red.svg";

import bettingPartnerImage from "assets/img/home/betting-partner.avif";
import mainPartnerImage from "assets/img/home/main-partner.avif";
import titlePartnerImage from "assets/img/home/title-partner.avif";

import bettingPartnerLogo from "assets/svg/home/sponsorship/betting-partner-logo.svg";
import mainPartnerLogo from "assets/svg/home/sponsorship/main-partner-logo.svg";
import titlePartnerLogo from "assets/svg/home/sponsorship/title-partner-logo.svg";

import supportImage from "assets/svg/home/supportImage.svg";
import supportBg from "assets/svg/home/supportBackground.svg";

export default function Home() {
  const { colorMode } = useColorMode();

  const isMobile = useBreakpointValue({ base: true, sm:true, msm:true, md: false, lg:false, xl:false, xxl:false });

  const [casinoBets, setCasinoBets] = useState([]);
  const [sportsBets, setSportsBets] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);

  const [hoverIndex, setHoverIndex] = useState(null);

  const bg = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("gray.600", "gray.300");
  const buttonColor = useColorModeValue("green.400");
  const secondaryTextColor = useColorModeValue("gray.400", "gray.400");
  const bgColor = useColorModeValue("white", "gray.800");
  const headerBgColor = useColorModeValue("gray.100", "gray.900");

  // CSS keyframes for continuous scroll effect
  const scroll = keyframes`
0% { transform: translateX(0); }
100% { transform: translateX(-100%); }
`;
  // Use different speeds for mobile and desktop
  const animationSpeed = useBreakpointValue({ base: "3s", md: "15s" });

  const imageSize = useBreakpointValue({ base: "60px", md: "100px" });
  const imageMargin = useBreakpointValue({ base: 10, md: 20 });

  // Simulate fetching data from an API
  useEffect(() => {
    const fetchCasinoBets = async () => {
      const data = [
        {
          game: "Salon Privé Baccarat E",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "Speed Blackjack 22",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "-1999.199920",
        },
        {
          game: "Salon Privé Baccarat E",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "4398.239824",
          multiplier: "0.00x",
          payout: "4398.239824",
        },
        {
          game: "Speed Blackjack 22",
          user: "Hidden",
          time: "7:09 PM",
          betAmount: "1999.199920",
          multiplier: "0.00x",
          payout: "2999.199920",
        },
        // Add more dummy data here
      ];
      setCasinoBets(data);
    };

    const fetchSportsBets = async () => {
      const data = [
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        {
          event: "Basketball - Lakers vs Warriors",
          user: "Hidden",
          time: "7:30 PM",
          odds: "1.95",
          betAmount: "500",
        },
        {
          event: "Football - Man Utd vs Liverpool",
          user: "Hidden",
          time: "8:00 PM",
          odds: "2.10",
          betAmount: "1000",
        },
        // Add more dummy data here
      ];
      setSportsBets(data);
    };

    const fetchLeaderboard = async () => {
      const data = [
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        { rank: 1, user: "Hidden", wagered: "10000", prize: "5000" },
        { rank: 2, user: "Hidden", wagered: "8000", prize: "4000" },
        // Add more dummy data here
      ];
      setLeaderboard(data);
    };

    fetchCasinoBets();
    fetchSportsBets();
    fetchLeaderboard();
  }, []);

  const bonuses = [
    {
      icon: FaBolt,
      label: "Bonus Boosts",
      description:
        "Weekly boosts and monthly bonuses for top games and sports betting.",
      video: bonusVideo,
    },
    {
      icon: FaStar,
      label: "Raffles & Races",
      description:
        "Participate in exciting raffles and races to win big rewards.",
      video: rafflesVideo,
    },
    {
      icon: FaGift,
      label: "Promotions",
      description: "Special promotions offering fantastic rewards and bonuses.",
      video: promotionsVideo,
    },
    {
      icon: FaBitcoin,
      label: "High Return-to-Player (RTP)",
      description: "Up to 99.5% RTP in selected games for more chances to win.",
      video: returnToPlayerVideo,
    },
  ];

  const [selectedVideo, setSelectedVideo] = useState(bonuses[0].video);
  const [progress, setProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Set interval to change video every 10 seconds (10000 milliseconds)
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % bonuses.length;
      setCurrentIndex(nextIndex);
      setSelectedVideo(bonuses[nextIndex].video);
      setProgress(0); // Reset progress
    }, 10000);

    // Update progress every second
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          return 0; // Reset progress when complete
        }
        return prevProgress + 1;
      });
    }, 100); // Update every 100 milliseconds

    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
    };
  }, [currentIndex]);

  const receivedBonuses = [
    "Weekly Bonus Received",
    "Monthly Bonus Received",
    "VIP Bonus Received",
    "Level-up Bonus Received",
    "Casino Bonus Received",
  ];

  const horizontalImages = [
    billieJeanKingCupImage,
    davisCupImage,
    drakeImage,
    enyimbaFcImage,
    evertonImage,
    juventudeImage,
    stakeF1Image,
    teamSecretImage,
    ufcImage,
  ];

  const verticalImages = [
    currencyUsdtImage,
    currencyBtcImage,
    currencyEthImage,
    currencyInrImage,
  ];

  const verticalSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    rtl: true,
    pauseOnHover: false,
  };

  const sponsorshipData = [
    {
      headerText: "Stake F1 Team Kick Sauber",
      title: "Stake F1 Team Kick Sauber",
      backgroundImage: greenBg,
      image: titlePartnerImage,
      logo: titlePartnerLogo,
      description: "Exclusive Title Partner",
      buttonText: "Learn more",
    },
    {
      headerText: "Official Betting Partner",
      title: "UFC Official Betting Partner",
      backgroundImage: redBg,
      image: bettingPartnerImage,
      logo: bettingPartnerLogo,
      description:
        "Our ground-breaking partnership with the UFC provides an unmatched betting experience...",
      buttonText: "Learn more",
    },
    {
      headerText: "Main Partner",
      title: "Main Partner Everton",
      backgroundImage: blueBg,
      image: mainPartnerImage,
      logo: mainPartnerLogo,
      description: "Main Partner with Everton",
      buttonText: "Learn more",
    },
  ];

  return (
    <Flex
      flexDirection="column"
      pt={{ base: "120px", md: "65px" }}
      bg={bg}
      color={textColor}
      minH="100vh"
    >
      <style>
        {`
          .table-wrapper {
              overflow-x: auto;
             -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
          }

          @media (max-width: 600px) {
            .hide-mobile {
              display: none;
            }
          }
        `}
      </style>
      <Box bg={bgColor} color={textColor}>
        {/* Header Section */}
        <div>
          {isMobile ? (
            // Mobile View Layout
            <Flex
              py={6}
              px={4}
              align="center"
              justify="center"
              bg={headerBgColor}
              flexDirection="column"
            >
              {/* Promotional Images at the top for mobile */}
              <Box display="flex" justifyContent="center" mb={6}>
                <Image
                  src={promotionalOne}
                  alt="Promotional Image 1"
                  objectFit="cover"
                  borderRadius="5%"
                  boxSize="50%"
                  mr={2}
                />
                <Image
                  src={promotionalSecond}
                  alt="Promotional Image 2"
                  objectFit="cover"
                  borderRadius="5%"
                  boxSize="50%"
                />
              </Box>

              {/* Heading and Sign Up Options */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                mb={6}
              >
                <Heading size="lg" mb={4}>
                  An unrivalled Online Casino & Sportsbook
                </Heading>
                <Button width="100%" colorScheme="blue" size="lg" mb={6}>
                  Sign up
                </Button>

                {/* Social Media Icons */}
                <Text color={secondaryTextColor}>Or sign up with</Text>
                <SimpleGrid columns={4} spacing={4} mt={4}>
                  <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                    <FacebookIcon boxSize={6} />
                  </Button>
                  <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                    <GoogleIcon boxSize={6} />
                  </Button>
                  <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                    <LineIcon boxSize={6} />
                  </Button>
                  <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                    <TwitchIcon boxSize={6} />
                  </Button>
                </SimpleGrid>
              </Box>
            </Flex>
          ) : (
            // Large Screen Layout (Desktop View)
            <Flex
              py={{ base: 6, md: 12 }}
              px={{ base: 4, md: 6 }}
              align="center"
              justify="space-between"
              backgroundImage={
                colorMode === "dark" ? `url(${headerBg})` : "none"
              }
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* Left Column: Heading and Sign Up Options */}
              <Box
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                mb={{ base: 6, md: 0 }}
              >
                <Box>
                  <Heading size={{ base: "xl", md: "2xl" }} mb={4}>
                    An unrivalled Online Casino & Sportsbook
                  </Heading>
                  <Button colorScheme="blue" size="lg" mb={6}>
                    Sign up
                  </Button>
                </Box>

                {/* Social Media Icons */}
                <Box mt={6}>
                  <Text color={secondaryTextColor} textAlign="center">
                    Or sign up with
                  </Text>
                  <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4} mt={4}>
                    <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                      <FacebookIcon boxSize={6} />
                    </Button>
                    <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                      <GoogleIcon boxSize={6} />
                    </Button>
                    <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                      <LineIcon boxSize={6} />
                    </Button>
                    <Button size="lg" bg="gray.600" _hover={{ bg: "gray.400" }}>
                      <TwitchIcon boxSize={6} />
                    </Button>
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Promotional Images */}
              <Box
                flex="1"
                display="flex"
                justifyContent="center"
                p={2}
                pl={{ base: 0, md: 20 }}
              >
                <Image
                  src={promotionalOne}
                  alt="Promotional Image 1"
                  objectFit="cover"
                  borderRadius="5%"
                  boxSize={{ base: "100%", md: "auto" }}
                />
              </Box>

              <Box flex="1" display="flex" justifyContent="center" p={2}>
                <Image
                  src={promotionalSecond}
                  alt="Promotional Image 2"
                  objectFit="cover"
                  borderRadius="5%"
                  boxSize={{ base: "100%", md: "auto" }}
                />
              </Box>
            </Flex>
          )}
        </div>

        <Flex bg="gray.700" overflow="hidden" p={4}>
          <Flex
            as="div"
            animation={`${scroll} linear infinite ${animationSpeed}`}
            minW="100%"
          >
            {horizontalImages.map((img, index) => (
              <Box key={index} mx={2}>
                <Image
                  src={img}
                  alt={`Logo ${index + 1}`}
                  objectFit="contain"
                  width={imageSize}
                  height={imageSize}
                  ml={imageMargin}
                  mr={imageMargin}
                />
              </Box>
            ))}
            {/* Repeat logos for continuous effect */}
            {horizontalImages.map((img, index) => (
              <Box key={index + horizontalImages.length} mx={2}>
                <Image
                  src={img}
                  alt={`Logo ${index + horizontalImages.length + 1}`}
                  objectFit="contain"
                  width={imageSize}
                  height={imageSize}
                  ml={imageMargin}
                  mr={imageMargin}
                />
              </Box>
            ))}
          </Flex>
        </Flex>

        <Box mt={8}>
          {/* Casino Section */}
          {isMobile ? (
            // Mobile Layout for Casino Section
            <Box backgroundImage={blueGradientMobile} py={6} px={4}>
              {/* Text Section */}
              <Box textAlign="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold" color="white" mb={2}>
                  Thousands of thrilling games.Thousands of ways to win big.
                </Text>
              </Box>

              {/* Image Section */}
              <Box display="flex" justifyContent="center" mb={4}>
                <Image
                  src={casinoBanner}
                  alt="Casino Banner"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Box>

              {/* Icons Section */}
              <Grid
                bg="rgba(255, 255, 255, 0.1)"
                templateColumns="1fr"
                gap={4}
                mb={4}
              >
                {[
                  { icon: GiCardJoker, label: "New Releases" },
                  { icon: FaDice, label: "Slots" },
                  { icon: FaTable, label: "Live Casino" },
                  { icon: FaGamepad, label: "Table Games" },
                ].map(({ icon, label }) => (
                  <Flex
                    key={label}
                    align="center"
                    justify="start"
                    p={4}
                    borderRadius="md"
                  >
                    <Icon as={icon} boxSize={6} color="white" mr={2} />
                    <Text color="white" fontWeight="bold">
                      {label}
                    </Text>
                  </Flex>
                ))}
              </Grid>

              {/* Button */}
              <Box display="flex" justifyContent="center">
                <Button width="100%" colorScheme="blue">
                  Explore Casino
                </Button>
              </Box>
            </Box>
          ) : (
            // Large Screen Layout for Casino Section
            <Box backgroundImage={blueGradient}>
              <Flex
                justify="space-between"
                alignItems="center"
                px={{ base: 4, md: 10 }}
              >
                {/* Text Section */}
                <Box maxW={{ base: "100%", md: "50%" }}>
                  <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="white"
                  >
                    Thousands of thrilling games
                  </Text>
                  <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="white"
                    mb={5}
                  >
                    Thousands of ways to win big
                  </Text>
                  <Grid
                    templateColumns={{
                      base: "repeat(2, 1fr)",
                      md: "repeat(4, 1fr)",
                    }}
                    gap={6}
                    mt={5}
                  >
                    {[
                      { icon: GiCardJoker, label: "New Releases" },
                      { icon: FaDice, label: "Slots" },
                      { icon: FaTable, label: "Live Casino" },
                      { icon: FaGamepad, label: "Table Games" },
                    ].map(({ icon, label }) => (
                      <Flex flexDirection="column" align="center" key={label}>
                        <Icon as={icon} boxSize={10} color="white" />
                        <Text color="white" mt={2}>
                          {label}
                        </Text>
                      </Flex>
                    ))}
                  </Grid>
                  <Button colorScheme="blue" mt={8}>
                    Explore Casino
                  </Button>
                </Box>

                {/* Image Section */}
                <Box maxW={{ base: "100%", md: "45%" }}>
                  <Image
                    src={casinoBanner}
                    alt="Casino Banner"
                    objectFit="cover"
                    borderRadius="md"
                  />
                </Box>
              </Flex>
            </Box>
          )}

          {/* Sports Section */}
          {isMobile ? (
            // Mobile Layout for Sports Section
            <Box backgroundImage={greenGradientMobile} py={6} px={4} mt={6}>
              {/* Text Section */}
              <Box textAlign="center" mb={4}>
                <Text fontSize="xl" fontWeight="bold" color="white" mb={2}>
                  Watch and bet on the biggest events across sports and beyond
                </Text>
              </Box>

              {/* Image Section */}
              <Box display="flex" justifyContent="center" mb={4}>
                <Image
                  src={sportsBanner}
                  alt="Sports Banner"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Box>

              {/* Icons Section */}
              <Grid
                bg="rgba(255, 255, 255, 0.1)"
                templateColumns="1fr"
                gap={4}
                mb={4}
              >
                {[
                  { icon: MdSportsSoccer, label: "Sports" },
                  { icon: GiConsoleController, label: "Esports" },
                  { icon: FaHorseHead, label: "Horse Racing" },
                  { icon: FaStream, label: "Live Streams" },
                ].map(({ icon, label }) => (
                  <Flex
                    key={label}
                    align="center"
                    justify="start"
                    p={4}
                    borderRadius="md"
                  >
                    <Icon as={icon} boxSize={6} color="white" mr={2} />
                    <Text color="white" fontWeight="bold">
                      {label}
                    </Text>
                  </Flex>
                ))}
              </Grid>

              {/* Button */}
              <Box display="flex" justifyContent="center">
                <Button width="100%" colorScheme="green">
                  Explore Sports
                </Button>
              </Box>
            </Box>
          ) : (
            // Large Screen Layout for Sports Section
            <Box backgroundImage={greenGradient} mt={10}>
              <Flex
                justify="space-between"
                alignItems="center"
                px={{ base: 4, md: 10 }}
              >
                {/* Text Section */}
                <Box maxW={{ base: "100%", md: "50%" }}>
                  <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="white"
                  >
                    Watch and bet on the biggest events.
                  </Text>
                  <Text
                    fontSize={{ base: "2xl", md: "3xl" }}
                    fontWeight="bold"
                    color="white"
                    mb={5}
                  >
                    Across sports and beyond.
                  </Text>
                  <Grid
                    templateColumns={{
                      base: "repeat(2, 1fr)",
                      md: "repeat(4, 1fr)",
                    }}
                    gap={6}
                    mt={5}
                  >
                    {[
                      { icon: MdSportsSoccer, label: "Sports" },
                      { icon: GiConsoleController, label: "Esports" },
                      { icon: FaHorseHead, label: "Horse Racing" },
                      { icon: FaStream, label: "Live Streams" },
                    ].map(({ icon, label }) => (
                      <Flex flexDirection="column" align="center" key={label}>
                        <Icon as={icon} boxSize={10} color="white" />
                        <Text color="white" mt={2}>
                          {label}
                        </Text>
                      </Flex>
                    ))}
                  </Grid>
                  <Button colorScheme="green" mt={8}>
                    Explore Sports
                  </Button>
                </Box>

                {/* Image Section */}
                <Box maxW={{ base: "100%", md: "45%" }}>
                  <Image
                    src={sportsBanner}
                    alt="Sports Banner"
                    objectFit="cover"
                    borderRadius="md"
                  />
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        bg={bgColor}
        p={{ base: 0, md: 8 }}
        pt={{ base: 8, md: 6 }}
        borderRadius="lg"
        color="white"
      >
        <Text
          color={textColor}
          fontSize={{ base: "xl", md: "2xl" }}
          fontWeight="bold"
          mb={4}
        >
          Enjoy the best odds with up to 99.5% return-to-player games
        </Text>

        {isMobile ? (
          // Mobile Layout
          <VStack spacing={4} align="start">
            {bonuses.map((bonus, idx) => (
              <Box
                key={idx}
                bg="rgba(255, 255, 255, 0.1)"
                p={4}
                borderRadius="md"
                w="100%"
              >
                <Flex align="center">
                  <Icon as={bonus.icon} boxSize={8} color="yellow.400" mr={4} />
                  <Box>
                    <Text color={textColor} fontSize="lg" fontWeight="bold">
                      {bonus.label}
                    </Text>
                    <Text color={textColor} fontSize="sm">
                      {bonus.description}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </VStack>
        ) : (
          // Large Screen Layout with Video Section
          <Flex direction={{ base: "column", md: "row" }} align="start">
            <VStack spacing={4} align="start" w={{ base: "100%", md: "50%" }}>
              {/* Left Section with Bonuses */}
              {bonuses.map((bonus, idx) => (
                <Box
                  key={idx}
                  position="relative"
                  bg={idx === currentIndex ? "gray.600" : "transparent"} // Grey background for selected item
                  borderRadius="md"
                >
                  <Flex
                    align="center"
                    cursor="pointer"
                    onClick={() => {
                      setSelectedVideo(bonus.video);
                      setCurrentIndex(idx);
                      setProgress(0); // Reset progress
                    }}
                    p={4}
                    width="100%"
                  >
                    <Icon
                      as={bonus.icon}
                      boxSize={8}
                      color="yellow.400"
                      mr={4}
                    />
                    <Box>
                      <Text color="white" fontSize="lg" fontWeight="bold">
                        {bonus.label}
                      </Text>
                      <Text color="white" fontSize="sm">
                        {bonus.description}
                      </Text>
                    </Box>
                  </Flex>

                  {/* Progress Bar for the currently selected item */}
                  {idx === currentIndex && (
                    <Box
                      position="absolute"
                      bottom="-10px" // Adjust as needed
                      left="0"
                      width="100%"
                      height="5px"
                      bg="gray.700"
                      borderRadius="md"
                      overflow="hidden"
                    >
                      <Box
                        width={`${progress}%`}
                        height="10px"
                        bgGradient="linear(to-r, transparent, blue.500)"
                        transition="width 0.1s linear"
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </VStack>

            {/* Right Section with Autoplayed Video */}
            <Box ml={{ base: 0, md: 8 }} mt={{ base: 4, md: 0 }} maxW="500px">
              <video
                src={selectedVideo}
                autoPlay
                muted
                loop
                controls={false}
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </Box>
          </Flex>
        )}
      </Box>

      <Flex
        bg={bgColor}
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
        p={{ base: 4, md: 6 }} // Adjust padding for mobile
      >
        {/* Conditional Rendering for Mobile vs Desktop */}
        {isMobile ? (
          <>
            {/* Right Section (Text, Subtext, Button) First on Mobile */}
            <Box
              flex="1"
              maxW={{ base: "100%", md: "50%" }}
              ml={{ base: 0, md: 6 }}
              mb={{ base: 4, md: 0 }} // Add margin at the bottom on mobile
            >
              <VStack spacing={{ base: 3, md: 4 }} align="start">
                <Text
                  fontSize={{ base: "xl", md: "3xl" }} // Adjust text size for mobile
                  fontWeight="bold"
                  color="white"
                >
                  Peace of mind across multiple crypto and local currencies
                </Text>
                <Text
                  fontSize={{ base: "md", md: "lg" }} // Adjust subtext size for mobile
                  color="gray.300"
                >
                  Experience safe deposits and instant withdrawals across 20
                  cryptocurrencies and 7 local currencies. All with no minimums,
                  flexible payment methods and a secure online vault.
                </Text>
                <Button
                  colorScheme="blue"
                  size={{ base: "md", md: "lg" }} // Adjust button size for mobile
                  w="100%"
                >
                  Learn more
                </Button>
              </VStack>
            </Box>

            {/* Slider Second on Mobile */}
            <Box
              flex="1"
              maxW={{ base: "100%", md: "100%" }}
              mr={{ base: 0, md: 6 }}
            >
              <Slider {...verticalSettings}>
                {verticalImages.map((img, index) => (
                  <Box key={index} height="100%" width="100%">
                    <Image
                      src={img}
                      alt={`Carousel image ${index + 1}`}
                      objectFit="cover"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                ))}
              </Slider>
            </Box>
          </>
        ) : (
          <>
            {/* Slider First on Desktop */}
            <Box
              flex="1"
              maxW={{ base: "100%", md: "100%" }}
              mr={{ base: 0, md: 6 }}
            >
              <Slider {...verticalSettings}>
                {verticalImages.map((img, index) => (
                  <Box key={index} height="100%" width="100%">
                    <Image
                      src={img}
                      alt={`Carousel image ${index + 1}`}
                      objectFit="cover"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                ))}
              </Slider>
            </Box>

            {/* Right Section (Text, Subtext, Button) Second on Desktop */}
            <Box
              flex="1"
              maxW={{ base: "100%", md: "50%" }}
              ml={{ base: 0, md: 6 }}
            >
              <VStack spacing={{ base: 3, md: 4 }} align="start">
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }} // Adjust text size for mobile
                  fontWeight="bold"
                  color="white"
                >
                  Peace of mind across multiple crypto and local currencies
                </Text>
                <Text
                  fontSize={{ base: "md", md: "lg" }} // Adjust subtext size for mobile
                  color="gray.300"
                >
                  Experience safe deposits and instant withdrawals across 20
                  cryptocurrencies and 7 local currencies. All with no minimums,
                  flexible payment methods and a secure online vault.
                </Text>
                <Button
                  colorScheme="blue"
                  size={{ base: "md", md: "lg" }} // Adjust button size for mobile
                >
                  Learn more
                </Button>
              </VStack>
            </Box>
          </>
        )}
      </Flex>

      <Box bg={bgColor} color="white" p={isMobile ? 0 : 8} borderRadius="lg">
        {isMobile ? (
          // Mobile Layout
          <VStack spacing={4} align="start" pt={4}>
            {/* Left Section with Main Message */}
            <Box>
              <Text color={textColor} fontSize="xl" fontWeight="bold" mb={4}>
                Wager more and see your rewards grow as a Stake VIP
              </Text>
              <Text color={textColor} fontSize="md" mb={4}>
                Step into a world of unique benefits, personalized service, and
                exclusive bonuses, only as a Stake VIP.
              </Text>
              <Button colorScheme="blue" size="lg" w="100%">
                Become a VIP
              </Button>
            </Box>

            {/* Right Section with Benefits */}
            <VStack spacing={4} align="start" w="100%">
              <Flex
                bg="rgba(255, 255, 255, 0.1)"
                borderRadius="md"
                align="center"
                w="100%"
                p={2}
              >
                <Icon as={FaRocket} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Bonuses every week
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Every week of each month, you’ll be rewarded with a bonus
                    based on your recent games. The more you bet, the higher the
                    bonuses.
                  </Text>
                </Box>
              </Flex>

              <Flex
                bg="rgba(255, 255, 255, 0.1)"
                borderRadius="md"
                align="center"
                w="100%"
                p={2}
              >
                <Icon as={FaCrown} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Your Own VIP Host
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Enjoy exclusive access to a dedicated VIP Host who will
                    support and cater to your betting needs.
                  </Text>
                </Box>
              </Flex>

              <Flex
                bg="rgba(255, 255, 255, 0.1)"
                borderRadius="md"
                align="center"
                w="100%"
                p={2}
              >
                <Icon as={FaTree} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Level Up Payouts
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Get paid each time you reach a new level. The higher you go,
                    the better the level-ups get.
                  </Text>
                </Box>
              </Flex>

              <Flex
                bg="rgba(255, 255, 255, 0.1)"
                borderRadius="md"
                align="center"
                w="100%"
                p={2}
              >
                <Icon as={FaRedo} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Recent Play Bonuses
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Looking to win back your unsuccessful wagers? Stake offers
                    money back on losses every time you level up.
                  </Text>
                </Box>
              </Flex>
            </VStack>
          </VStack>
        ) : (
          // Desktop Layout
          <Flex justify="space-between" align="center">
            {/* Left Section with Main Message */}
            <Box maxW="50%">
              <Text color={textColor} fontSize="3xl" fontWeight="bold" mb={4}>
                Wager more and see your rewards grow as a Stake VIP
              </Text>
              <Text color={textColor} fontSize="lg" mb={6}>
                Step into a world of unique benefits, personalized service, and
                exclusive bonuses, only as a Stake VIP.
              </Text>
              <Button colorScheme="blue" size="lg">
                Become a VIP
              </Button>
            </Box>

            {/* Right Section with Benefits */}
            <VStack spacing={4} align="start" width="50%">
              <Flex p={4} borderRadius="md" align="center" width="100%">
                <Icon as={FaRocket} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Bonuses every week
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Every week of each month, youll be rewarded with a bonus
                    based on your recent games. The more you bet, the higher the
                    bonuses.
                  </Text>
                </Box>
              </Flex>

              <Flex p={4} borderRadius="md" align="center" width="100%">
                <Icon as={FaCrown} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Your Own VIP Host
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Enjoy exclusive access to a dedicated VIP Host who will
                    support and cater to your betting needs.
                  </Text>
                </Box>
              </Flex>

              <Flex p={4} borderRadius="md" align="center" width="100%">
                <Icon as={FaTree} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Level Up Payouts
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Get paid each time you reach a new level. The higher you go,
                    the better the level-ups get.
                  </Text>
                </Box>
              </Flex>

              <Flex p={4} borderRadius="md" align="center" width="100%">
                <Icon as={FaRedo} boxSize={8} color="red.500" mr={4} />
                <Box>
                  <Text color={textColor} fontSize="lg" fontWeight="bold">
                    Recent Play Bonuses
                  </Text>
                  <Text color={textColor} fontSize="sm">
                    Looking to win back your unsuccessful wagers? Stake offers
                    money back on losses every time you level up.
                  </Text>
                </Box>
              </Flex>
            </VStack>
          </Flex>
        )}
      </Box>

      <Flex>
        {isMobile ? (
          // Mobile Layout
          <Flex direction="column" p={4} gap={4}>
            <Box>
              <Text color={textColor} fontSize="2xl" fontWeight="bold" mb={4}>
                Meet our sponsorship partners
              </Text>
              <Text color={textColor} fontSize="md" mb={6}>
                We proudly sponsor high-profile athletes, teams, and stars from
                across the globe to help support the passion and excitement of
                our communities.
              </Text>
            </Box>

            {sponsorshipData.map((sponsor, index) => (
              <Box
                backgroundImage={sponsor.backgroundImage}
                key={index}
                position="relative"
                w="100%" // Full width for mobile
                h="250px" // Fixed height for each image
                overflow="hidden"
                borderRadius="md"
                boxShadow="md"
                mb={4} // Add margin between images
              >
                {/* Blurred Background for Text */}
                <Box
                  backdropFilter="blur(10px)" // Frosted glass blur effect
                  backgroundColor="rgba(255, 255, 255, 0.1)" // Light translucent background
                  width="100%" // Make it take full width
                  textAlign="center" // Center the text inside
                  px={4} // Padding for horizontal spacing
                  py={2} // Padding for vertical spacing
                  mb={2} // Space between text and logo
                  borderRadius="md" // Rounded corners for a smooth look
                  boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)" // Optional shadow for enhancement
                >
                  <Text fontSize="lg" fontWeight="bold" color="white">
                    {sponsor.headerText}
                  </Text>
                </Box>
                {/* Sponsor Image */}
                <Image
                  src={sponsor.image}
                  alt={sponsor.title}
                  objectFit="cover"
                  w="80%" // Adjust width as needed
                  h="80%" // Adjust height as needed
                  position="absolute" // Absolute positioning
                  bottom={0} // Align to the bottom
                  right={-30} // Align to the right
                />

                <Box
                  position="absolute"
                  p={4}
                  color="white"
                  w="100%"
                  textAlign="left"
                >
                  {/* Sponsor Logo */}
                  <Image
                    src={sponsor.logo} // Add the logo URL here
                    alt="logo"
                    w="70px" // Adjust size of the logo
                    h="70px"
                    objectFit="contain"
                    mb={4} // Add margin below the logo for spacing
                  />
                </Box>

                {/* Static Text Below Logo */}
                <Box
                  position="absolute"
                  bottom={0}
                  p={4}
                  color="white"
                  w="100%"
                  textAlign="left"
                >
                  <Button
                    variant="outline"
                    borderColor="white"
                    borderRadius="0"
                  >
                    {sponsor.buttonText}
                  </Button>
                </Box>
              </Box>
            ))}
          </Flex>
        ) : (
          // Desktop Layout
          <Flex direction="column" p={4} gap={8} height="auto">
            {/* Text Section */}
            <Box maxW="100%">
              <Text
                textAlign="center"
                color={textColor}
                fontSize="4xl"
                fontWeight="bold"
                mb={4}
              >
                Meet our sponsorship partners
              </Text>
              <Text textAlign="center" color={textColor} fontSize="md" mb={6}>
                We proudly sponsor high profile athletes, teams, and stars from
                across the globe to help support the passion and excitement of
                our communities.
              </Text>
            </Box>

            {/* Sponsorship Image Section */}
            <Flex
              justify="space-between"
              align="center"
              gap={4}
              height="600px"
              overflow="hidden"
            >
              {sponsorshipData.map((sponsor, index) => (
                <Box
                  backgroundImage={sponsor.backgroundImage}
                  key={index}
                  position="relative"
                  w={hoverIndex === index ? "80%" : "40%"}
                  h="100%"
                  overflow="hidden"
                  borderRadius="xl"
                  boxShadow="xl"
                  cursor="pointer"
                  transition="all 0.3s ease"
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  {/* Heading */}
                  <Flex
                    direction="column"
                    align="center"
                    position="absolute"
                    top={0}
                    w="100%"
                    zIndex={1}
                  >
                    {/* Blurred Background for Text */}
                    <Box
                      backdropFilter="blur(10px)" // Frosted glass blur effect
                      backgroundColor="rgba(255, 255, 255, 0.1)" // Light translucent background
                      width="100%" // Make it take full width
                      textAlign="center" // Center the text inside
                      px={4} // Padding for horizontal spacing
                      py={2} // Padding for vertical spacing
                      mb={2} // Space between text and logo
                      borderRadius="md" // Rounded corners for a smooth look
                      boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)" // Optional shadow for enhancement
                    >
                      <Text fontSize="lg" fontWeight="bold" color="white">
                        {sponsor.headerText}
                      </Text>
                    </Box>

                    {/* Sponsor Logo */}
                    <Image
                      src={sponsor.logo} // Add the logo URL here
                      alt="logo"
                      w="50px" // Adjust size of the logo
                      h="50px"
                      objectFit="contain"
                      mb={4} // Add margin below the logo for spacing
                    />
                  </Flex>

                  {/* Sponsor Image */}
                  <Image
                    pt={20}
                    src={sponsor.image}
                    alt={sponsor.title}
                    objectFit="cover"
                    w="100%"
                    h="100%"
                    transition="filter 0.3s ease"
                  />

                  {hoverIndex === index && (
                    <Box
                      position="absolute"
                      bottom={0}
                      p={6}
                      color="white"
                      w="100%"
                      h="auto"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      transition="all 0.3s ease"
                    >
                      <Flex direction="column" align="flex-start">
                        <Text fontSize="lg" fontWeight="bold">
                          {sponsor.title}
                        </Text>
                        <Text fontSize="md">{sponsor.description}</Text>
                      </Flex>
                      <Button
                        variant="outline"
                        borderColor="white"
                        borderRadius="0"
                      >
                        {sponsor.buttonText}
                      </Button>
                    </Box>
                  )}
                </Box>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>

      <Box
  backgroundImage={supportBg}
  backgroundPosition="center"  // Align the background image in the center
  backgroundSize="cover"  // Ensure the image covers the entire box without distortion
  backgroundRepeat="no-repeat"  // Prevent the image from repeating
  color="white"
  p={isMobile ? 0 : 8}
  borderRadius="lg"
>
        {isMobile ? (
          // Mobile Layout
          <VStack spacing={4} align="start" pt={4}>
            {/* Left Section with Main Message */}
            <Box p={4}>
              <Text color={textColor} fontSize="2xl" fontWeight="bold" mb={4}>
                Our help team speaks your language
              </Text>
              <Text color={textColor} fontSize="md">
                Get 24/7 support from our live customer support team in your
                native language
              </Text>
            </Box>

            {/* Right Section with Benefits */}
            <VStack spacing={4} align="start" w="100%">
              <Box maxW="100%" p={4}>
                <Image
                  src={supportImage}
                  alt="Community Image"
                  borderRadius="lg"
                  boxShadow="lg"
                  width="100%"
                />
              </Box>
            </VStack>
          </VStack>
        ) : (
          // Desktop Layout
          <Flex justify="space-between" align="center">
            {/* Left Section with Main Message */}
            <Box maxW="50%">
              <Text color={textColor} fontSize="4xl" fontWeight="bold" mb={4}>
                Our help team speaks your language
              </Text>
              <Text color={textColor} fontSize="md" mb={6}>
                Get 24/7 support from our live customer support team in your
                native language.
              </Text>
            </Box>

            {/* Right Section with Benefits */}
            <VStack spacing={4} align="end" width="60%">
              <Box maxW="100%" p={4}>
                <Image
                  src={supportImage}
                  alt="Community Image"
                  borderRadius="lg"
                  boxShadow="lg"
                  width="100%"
                />
              </Box>
            </VStack>
          </Flex>
        )}
      </Box>

      <Tabs
        boxShadow="lg"
        variant="solid-rounded"
        colorScheme="teal"
        size="md"
        mb={12}
        mt={12}
      >
        <TabList>
          <Tab>Casino Bets</Tab>
          <Tab>Sports Bets</Tab>
          <Tab>Race Leaderboard</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* Casino Bets content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Game</Th>
                    <Th>User</Th>
                    <Th>Time</Th>
                    <Th>Bet Amount</Th>
                    <Th>Multiplier</Th>
                    <Th>Payout</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {casinoBets.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.game}</Td>
                      <Td>{bet.user}</Td>
                      <Td>{bet.time}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>{bet.multiplier}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.payout}
                          <FaBitcoin
                            color="orange"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* Sports Bets content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Event</Th>
                    <Th>User</Th>
                    <Th>Time</Th>
                    <Th>Odds</Th>
                    <Th>Bet Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sportsBets.map((bet, index) => (
                    <Tr key={index}>
                      <Td>{bet.event}</Td>
                      <Td>{bet.user}</Td>
                      <Td>{bet.time}</Td>
                      <Td>{bet.odds}</Td>
                      <Td>
                        <Flex align="center">
                          {bet.betAmount}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>

          <TabPanel>
            {/* Race Leaderboard content */}
            <div className="table-wrapper">
              <Table variant="striped" color={secondaryTextColor} size="sm">
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>User</Th>
                    <Th>Wagered</Th>
                    <Th>Prize</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {leaderboard.map((entry, index) => (
                    <Tr key={index}>
                      <Td>{entry.rank}</Td>
                      <Td>{entry.user}</Td>
                      <Td>
                        <Flex align="center">
                          {entry.wagered}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                      <Td>
                        <Flex align="center">
                          {entry.prize}
                          <FaDollarSign
                            color="green"
                            style={{ marginLeft: "5px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box bg={bgColor} color="white" p={isMobile ? 0 : 8}>
        <Flex
          justify="space-between"
          align="center"
          flexWrap="wrap"
          direction={isMobile ? "column-reverse" : "row"} // Reverse for mobile
        >
          {/* Left Section with Single Image */}
          <Box maxW={isMobile ? "100%" : "50%"} p={isMobile ? 0 : 4}>
            <Image
              src={communityImage}
              alt="Community Image"
              borderRadius="lg"
              boxShadow="lg"
              width="100%"
            />
          </Box>

          {/* Right Section with Heading, Text, and Button */}
          <VStack
            align="start"
            spacing={6}
            maxW={isMobile ? "100%" : "45%"}
            p={4}
          >
            <Text
              color={textColor}
              fontSize={isMobile ? "2xl" : "3xl"}
              fontWeight="bold"
            >
              Share your excitement with a thriving community
            </Text>
            <Text color={textColor} fontSize="sm">
              From beginners and casual players to high rollers and expert
              players, our community loves to support one another. Get tips and
              tricks through our online chat, forums, and more.
            </Text>
            <Button colorScheme="blue" size="lg">
              Join the community
            </Button>
          </VStack>
        </Flex>
      </Box>

      <Box bg={bgColor} color="white" py={10} px={isMobile ? 0 : 8}>
        {/* Grid Layout */}
        <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={10}>
          {/* Left Section: Text and Button */}
          <Box px={isMobile ? 8 : 0} pt={isMobile ? 0 : 20}>
            <Text color={textColor} fontSize="3xl" fontWeight="bold" mb={4}>
              Still have questions?
            </Text>
            <Button colorScheme="blue" size="lg">
              Read our guides
            </Button>
          </Box>

          {/* Right Section: Accordion FAQ */}
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    Who is Stake?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                Stake is a leading online gaming platform with a wide variety of
                betting options.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    Is Stake licensed?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                Yes, Stake operates under a legitimate gaming license ensuring
                player safety and fair play.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    Is betting on Stake safe?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                Betting on Stake is safe thanks to advanced encryption and a
                strong privacy policy.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    What currencies can I bet with?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                You can bet using various cryptocurrencies including Bitcoin,
                Ethereum, and more.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    What types of casino games can I play?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                Stake offers a wide range of casino games including slots,
                blackjack, roulette, and more.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    What sports can I bet on?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                You can bet on a variety of sports including football,
                basketball, UFC, and more.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton color={textColor}>
                  <Box flex="1" textAlign="left">
                    How do I watch live streams?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel color={secondaryTextColor} pb={4}>
                Live streams can be watched directly on Stake platform for
                various events.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Grid>
      </Box>
    </Flex>
  );
}
